import { useTranslation } from "react-i18next";

import { Pill } from "@aviary";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreOffersPill.styles";
interface Props {
  patientAttributes: PatientAttributes;
}

const StoreOffersPill = ({ patientAttributes }: Props) => {
  const { t } = useTranslation();
  const { isUS } = useSharedGlobalConfig();
  const { storeDiscount } = patientAttributes;

  const renderDiscountPill = () => {
    if (!storeDiscount) return null;

    return (
      <Pill isColor="success" css={styles.pill}>
        {t(l.sharedCommon.discountPill, { discount: storeDiscount })}
      </Pill>
    );
  };

  const renderFreeStandardShipping = () => {
    const { freeShippingThreshold, storeHasFreePriorityShipping } = patientAttributes;

    if (!freeShippingThreshold && !storeHasFreePriorityShipping) return null;

    if (!storeHasFreePriorityShipping && !isUS) return null;

    const text =
      storeHasFreePriorityShipping || (isUS && freeShippingThreshold === 0)
        ? t(l.patientRxAuth.FreeStandardShippingZeroThreshold)
        : t(l.patientRxAuth.FreeStandardShipping, {
            freeShippingThreshold,
          });

    return (
      <Pill data-testid="standard-shipping-pill" isColor="success" css={styles.pill}>
        {text}
      </Pill>
    );
  };

  const renderFreePriorityShipping = () => {
    const { freeShippingThreshold, storeHasFreePriorityShipping } = patientAttributes;

    const hasFreePriorityShipping = !isUS || storeHasFreePriorityShipping;

    if (hasFreePriorityShipping) {
      return (
        <Pill data-testid="priority-shipping-pill" isColor="success" css={styles.pill}>
          {freeShippingThreshold === 0
            ? t(l.patientRxAuth.FreePriorityShippingZeroThreshold)
            : t(l.patientRxAuth.FreePriorityShipping, {
                freeShippingThreshold,
              })}
        </Pill>
      );
    }
  };

  return (
    <div css={styles.pillContainer}>
      {renderDiscountPill()}
      {renderFreeStandardShipping()}
      {renderFreePriorityShipping()}
    </div>
  );
};

export { StoreOffersPill };
